<template>
  <div class="boothReserveMobile" v-loading="show_loading">
    <div class="areaDomContent">
      <div class="areaDom" id="areaDom">
        <boothMap :selected="selectBooth" :alreadySelect="alreadySelect" :activity_id="activity_id"/>
      </div>
    </div>
    <div class="headerColor">
      <div class="colorAndText">
        <div class="color red fl"></div>
        <div class="text fl">已售展台</div>
      </div>
      <div class="colorAndText">
        <div class="color green fl"></div>
        <div class="text fl">我的展台</div>
      </div>
    </div>
    <div class="form">
      <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm"
          label-position="left"
      >
        <el-form-item label="展馆" required>
          <div class="booth">
            <div class="addBooth" @click="addExhibition">
              <img
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/expoSH2023/home/addBooth.png"
                  alt=""
              />
              添加展馆
            </div>
            <div class="select-exhibition">
              <el-tag
                class="select-exhibition-item"
                v-for="(item, index) in selectExhibition"
                :key="index"
                type="success"
                closable
                @close="deletedSomeExhibition(index)"
            >
              {{ item.name_zh }}
            </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="展位" required>
          <div class="booth">
            <div class="addBooth" @click="addBooth">
              <img
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/expoSH2023/home/addBooth.png"
                  alt=""
              />
              添加展位
            </div>
            <div class="select">
              <div
                  class="select-item"
                  v-for="(item, index) in selectBooth"
                  :key="index"
              >
                <span>
                  {{ item.booth_no }}({{ item.hbh_booth_type_name }}
                  {{ item.price_cny + "元" || ('$' + item.price_usd) }}/展位 )
                </span>
                <i class="el-icon-close" @click="deletedSomeBooth(index)"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="control-btn">
      <span>
        共{{ selectBooth.length }}项 合计：<span class="red"
      >¥{{ total_fee }}<span v-if="usdTotal" style="margin-left:10px;">{{ '$' + usdTotal }}</span></span
      >
      </span>
      <el-button
          :loading="loading"
          size="small"
          :disabled="is_apply || !(selectBooth.length && selectExhibition.length)"
          @click="submit"
          :class="[(selectBooth.length&&selectExhibition.length) && !is_apply ? 'activeSaveBtn' : '']"
      >
        提交预定
      </el-button>
    </div>
    <chooseBoth ref="chooseBoth" @checkBack="checkBack" :activity_id="activity_id"/>
    <selectExhibition ref="selectExhibition" :activity_id="activity_id" @checkBack="checkExhibition"/>
  </div>
</template>

<script>
import boothMap from "~exp/components/expoBooth/boothMap";
import chooseBoth from "~exp/components/expoBooth/chooseBoth";
import selectExhibition from "~exp/components/expoBooth/selectExhibition";
import {validateTelephone, validateEmail} from "~/baseUtils/validate";

export default {
  name: "boothMobile",
  metaInfo: {
    title: "预定展位",
  },
  components: {
    boothMap,
    chooseBoth,
    selectExhibition
  },
  data() {
    return {
      isBooked: false,
      user_id: 258,//258 588
      company_id: 234,//234 479 252 478
      activity_id: 141,//141 263 210
      selectBooth: [],
      selectExhibition: [],
      alreadySelect: [],
      ruleForm: {
        username: "",
        position: "",
        company: "",
        mobile: "",
        email: "",
        book_id: "",
      },
      bookedMsg: {},
      total_fee: 0, //总费用
      usdTotal: 0,
      booth_ids: "",
      loading: false,
      is_apply: false,
      show_loading: false,
    };
  },
  async mounted() {
    this.show_loading = true;
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
    if (this.$route.query.company_id) {
      this.company_id = this.$route.query.company_id;
    }
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id;
    }
    await Promise.all([this.initAllInfo(), this.getExhibition(), this.getExhibitionBasic()])
    this.show_loading = false
  },
  methods: {
    async initAllInfo() {
      this.initPinchzoom();
    },
    initPinchzoom() {
      this.$nextTick(() => {
        $("div.areaDom").each(function () {
          new RTP.PinchZoom($(this), {});
        });
      });
    },
    async getExhibitionBasic() {
      let res = await this.$store.dispatch(`register/getExhibitionBasic`, {
        activity_id:this.activity_id
      })
      if (res.success) {
        let exhibitors_params = res.data.apply_params;
        try {
          exhibitors_params = exhibitors_params ? JSON.parse(exhibitors_params) : {};
        } catch (error) {
          exhibitors_params = {};
        }
        if (exhibitors_params.exhibitors) {
          let nowTime = moment().unix();
          this.is_apply =
            exhibitors_params.exhibitors.apply_time_end &&
            nowTime > exhibitors_params.exhibitors.apply_time_end
              ? true
              : false;
        }  
      }
    },
    async getExhibition() {
      let params={
        activity_id:this.activity_id,
        company_id:this.company_id,
        nopage:1
      }
      let res = await this.$store.dispatch(`register/getReserveExhibition`, params)
      if (res.success) {
        this.selectExhibition=res.data
      }
    },
    addExhibition() {
      this.$refs.selectExhibition.openInit(this.selectExhibition);
    },
    addBooth() {
      this.$refs.chooseBoth.openInit(this.selectBooth);
    },
    deletedSomeBooth(index) {
      this.selectBooth.splice(index, 1);
      this.computedFree();
    },
    deletedSomeExhibition(index) {
      this.selectExhibition.splice(index, 1)
    },
    checkBack(e) {
      this.selectBooth = JSON.parse(JSON.stringify(e.check_arr));
      this.computedFree();
    },
    checkExhibition(e) {
      this.selectExhibition = JSON.parse(JSON.stringify(e.check_arr));
    },
    // 计算费用
    computedFree() {
      let ids = [];
      let total_fee = 0; //总费用
      let usdTotal = 0//美元费用
      this.selectBooth.forEach((item, i) => {
        ids.push(item.id);
        if (item.price_cny) {
          total_fee += item.price_cny * 1;
        } else {
          usdTotal += item.price_usd * 1;
        }
      });
      this.total_fee = total_fee;
      this.usdTotal = usdTotal
      this.booth_ids = ids.join(",");
    },
    async submit() {
      if (this.loading || !this.selectBooth.length || !this.selectExhibition.length) {
        return false;
      }
      this.loading = true;

      let params = {
        activity_id: this.activity_id,
        created_from: 1, // '创建来源：1-前台，2-后台',
        user_id: this.user_id,
        company_id: this.company_id,
        booth_ids: this.booth_ids,
        total_fee: {
          "CNY": this.total_fee,
          "USD": this.usdTotal
        }, //总费用
        pavilion: this.selectExhibition.map(ee => ee.id).join(','),
        _NOCLEAR: true,
      };
      let res = null;
      params.total_fee = JSON.stringify(params.total_fee)
      res = await this.$store.dispatch("register/expoBoothSave", params);

      if (res.success) {
        this.loading = false;
        // 提交成功
        wx.miniProgram.redirectTo({
          url: "/pronPackage/pages/bookInfo/index",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.red {
  color: #ff0000;
}

.boothReserveMobile {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 100px;

  .areaDomContent {
    padding: 0 15px;
  }

  .headerColor {
    width: 100%;
    height: 50px;
    background-color: #fefefe;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;

    .colorAndText {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 14px;
    }

    .color {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      text-align: center;
      line-height: 18px;
      font-size: 18px;
      font-weight: bold;

      &.red {
        background: #ffd966;
      }

      &.green {
        background: #ff5a5e;
      }
    }
  }

  .form {
    background-color: #fff;
    padding: 0 15px;
    margin-top: 10px;

    /deep/ .el-form-item__content {
      line-height: 30px;
    }

    /deep/ .el-form-item {
      border-bottom: 1px solid #ebedf0;
      margin-bottom: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::before {
      content: ' ';
      color: #F56C6C;
      margin-right: 0;
    }

    /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
      content: '*';
      color: #F56C6C;
      margin-left: 4px;
    }


    /deep/ .el-input__inner {
      border-color: transparent;
      text-align: right;
      padding-right: 0;
    }

    .booth {
      .addBooth {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        color: #c8c9cc;
        //padding-right: 15px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }

      .select {
        margin-top: 20px;

        .select-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          background: #19b39419;
          border-radius: 4px;
          margin-bottom: 10px;
          color: #19b394;
          .el-icon-close{
            cursor: pointer;
          }
        }
      }

      .select-exhibition {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .select-exhibition-item {
          margin-right: 10px;
          margin-bottom: 10px;
          color: #19b394;
          border-color: #19b39419;
          background-color: #19b39419;

          /deep/ .el-tag__close {
            color: #19b394 !important;

            &:hover {
              background-color: #19b39419 !important;
            }
          }
        }

      }
    }
  }

  .control-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    color: #000;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    height: calc(50px + constant(safe-area-inset-bottom));
    height: calc(50px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
  }
}

.activeSaveBtn {
  &:link, &:hover, &:active, &:visited {
    color: #19b394;
    border-color: #19b39419;
    background-color: #19b39419;
  }

  &.activeSaveBtn {
    color: #fff;
    border-color: #19b39419;
    background-color: #19b394;
  }
}
</style>
