<template>
  <div>
    <el-drawer title="选择展馆" :visible.sync="boothDialogShow" direction="btt">
      <div class="boothContent">
        <div style="margin-bottom:10px;padding-left:10px">
          <p style="margin-bottom:10px">已选择展馆：</p>
          <div v-if="boothChooseArr.length" class="tabBox">
            <el-tag
                class="tags"
                v-for="(item, i) in boothChooseArr"
                :key="i"
                type="success"
                closable
                @close="handleClose(item, i)"
            >
              {{ item.name_zh }}
            </el-tag>
          </div>
        </div>
        <div
            class="boothBox"
            v-loading="boothDialogLoading"
            style="min-height:300px"
        >
          <div class="boothConten" v-if="boothAllArr.length">
            <div
                class="boothContenItem "
                :class="{ active: item.checked }"
                v-for="(item, i) in boothAllArr"
                :key="i"
                @click="handleClick(item)"
            >
              <span>
                {{ item.name_zh }}
              </span>
              <span
                  class="txtColor el-icon-check"
                  style="font-weight: 800"
                  v-show="item.checked"
              ></span>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <el-button @click="boothDialogShow = false" style="width:40%"
          >取 消
          </el-button
          >
          <el-button style="width:60%;background-color:#19b394;color: #fff;border-color: #19b39419;" @click="chooseSure"
          >确 定
          </el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: "selectExhibition",
  props: {
    activity_id: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {
      boothDialogShow: false,
      boothDialogLoading: false,
      boothTypeArr: [],
      booty_type: 0,
      boothChooseArr: [], // 已选择的数据
      boothAllArr: [],
      boothActiveIndex: 0,
      seaveLoading: false,
    };
  },
  mounted() {
  },
  methods: {
    // 选择展位初始化
    openInit(boothCheckArr = []) {
      this.boothDialogShow = true;
      this.boothChooseArr = JSON.parse(JSON.stringify(boothCheckArr));
      this.getAllBooth();
    },
    // 获取可选展位数据
    async getAllBooth() {
      this.boothDialogLoading = true;
      let params = {
        activity_id: this.activity_id,
        nopage: 1,
      };
      let res = await this.$store.dispatch("register/getExhibition", params);
      console.log(res)
      if (res.success) {
        this.boothAllArr = res.data;
        this.boothDialogLoading = false;
        this.couputedIsChecked();
      }
    },

    // 计算已选择的数据
    couputedIsChecked() {
      let existArr = [];
      this.boothChooseArr.forEach((item) => {
        existArr.push(item.id);
      });
      this.boothAllArr.forEach((item) => {
        if (existArr.indexOf(item.id) != -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    },
    // 选择 或取消选择
    handleClick(item) {
      let allArr = this.boothAllArr.concat([])
      if (item.checked == true) {
        // 选中
        let i = this.boothChooseArr.findIndex((el) => el.id == item.id);
        this.boothChooseArr.splice(i, 1);
      } else {
        //  取消选中
        this.boothChooseArr.push(item);
      }
      this.$nextTick(() => {
        let index = allArr.findIndex((e) => e.id == item.id);
        allArr[index].checked = !item.checked;
        this.boothAllArr = allArr.concat([])
      });
    },
    // 在已选择的数据删除
    handleClose(item, i) {
      this.boothChooseArr.splice(i, 1);
      this.boothAllArr.forEach(ee=>{
        if (ee.id === item.id) {
          ee.checked=false
        }
      })
    },
    // 展位确定选择
    chooseSure() {
      this.boothDialogShow = false;
      this.$emit("checkBack", {
        check_arr: JSON.parse(JSON.stringify(this.boothChooseArr)),
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-drawer {
  height: auto !important;
  max-height: 90%;
  overflow-y: scroll;
}

/deep/ #el-drawer__title {
  margin-bottom: 0px !important;
  padding: 10px;
}

/deep/ .el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

.txtColor {
  color: #19b394;
  cursor: pointer;
}

.boothContent {
  width: 100%;
  margin: auto;
}

.boothBox {
  margin-top: 20px;
  display: flex;

  .area {
    background: #f7f7f7;
    width: 100px;

    .areaItem {
      text-align: center;
      padding: 15px;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    .areaItem_active {
      color: #333333;
      background: #fff;
    }
  }

  .boothConten {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    padding-left: 20px;

    .boothContenItem {
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      cursor: pointer;
    }

    .active {
      color: #19b394;
      font-weight: 800 !important;
    }
  }
}

.tabBox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-right: 30px;

  .tags {
    color: #19b394;
    border-color: #19b39419;
    background-color: #19b39419;

    /deep/ .el-tag__close {
      color: #19b394 !important;

      &:hover {
        background-color: #19b39419 !important;
      }
    }
  }

}

.btnBox {
  margin-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 20px;
}

.empty {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999;
}

.checkItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f5ff;
  padding: 0 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #19b394;

  i {
    cursor: pointer;
  }
}

.boothType {
  display: flex;
  align-items: flex-start;

  .boothText {
    flex-shrink: 0;
    padding-left: 10px;
    height: 28px;
    line-height: 28px;
  }

  .btn {
    button {
      margin-left: 0 !important;
      margin-right: 10px !important;
      margin-bottom: 10px !important;

      &:link, &:hover, &:active, &:visited {
        color: #19b394;
        border-color: #19b39419;
        background-color: #19b39419;
      }

      &.activeBtn {
        color: #19b394;
        border-color: #19b39419;
        background-color: #19b39419;
      }

    }
  }
}
</style>
